if (typeof UPLOADCARE_CROP == 'undefined') {
    let UPLOADCARE_CROP = '';
}
(function() {

    if (typeof tinymce !== 'undefined') init();

    function init() {
        tinymce.ScriptLoader.add('https://ucarecdn.com/widget/2.x/uploadcare/uploadcare.full.min.js');

        tinymce.create('tinymce.plugins.UploadcarePlugin', {
            init: function (ed, url) {
                tinymce.ScriptLoader.loadQueue();

                ed.addButton('uploadcare', {
                    title: 'Uploadcare',
                    cmd: 'showUploadcareDialog',
                    image: '/images/ucare-logo.png',
                    stateSelector: 'img'
                });

                ed.addCommand('showUploadcareDialog', function () {
                    var dialog = uploadcare.openDialog([], null).done(function (file) {
                        file.done(function (fileInfo) {
                            if (fileInfo.isImage) {
                                var dWidth = 800;
                                if (fileInfo.crop && fileInfo.crop.width < dWidth) {
                                    dWidth = fileInfo.crop.width;
                                }
                                ed.execCommand('mceInsertContent', false, '<img width="' + dWidth + '" src="' + fileInfo.cdnUrl + '-/resize/' + dWidth + 'x/" />');
                            } else {
                                ed.execCommand('mceInsertContent', false, '<a href="' + fileInfo.cdnUrl + '">' + fileInfo.name + '</a>');
                            }
                        });
                    });
                });
            },

            createControl: function (n, cm) {
                return null;
            },

            getInfo: function () {
                return {
                    longname: 'Uploadcare',
                    author: 'Uploadcare',
                    authorurl: 'https://uploadcare.com/',
                    infourl: 'https://github.com/uploadcare/uploadcare-tinymce',
                    version: "2.1.1"
                };
            }
        });

        tinymce.PluginManager.add('uploadcare', tinymce.plugins.UploadcarePlugin);
    }
})();
